<template>
  <v-card flat class="pa-3 mt-2">
    <!--    <v-card-text class="d-flex">-->
    <!--      <v-avatar rounded size="120" class="me-6">-->
    <!--        <v-img :src="accountDataLocale.avatarImg"></v-img>-->
    <!--      </v-avatar>-->

    <!--      &lt;!&ndash; upload photo &ndash;&gt;-->
    <!--      <div>-->
    <!--        <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">-->
    <!--          <v-icon class="d-sm-none">-->
    <!--            {{ icons.mdiCloudUploadOutline }}-->
    <!--          </v-icon>-->
    <!--          <span class="d-none d-sm-block">Upload new photo</span>-->
    <!--        </v-btn>-->

    <!--        <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg" :hidden="true" />-->

    <!--        <p class="text-sm mt-5">Allowed JPG or PNG. Max size of 800K</p>-->
    <!--      </div>-->
    <!--    </v-card-text>-->

    <v-card-text>
      <v-form ref="accountForm" v-model="valid" class="multi-col-validation mt-6" @submit.prevent="handleFormSubmit">
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="recruiter.user.firstName"
              label="First name"
              dense
              outlined
              :rules="[validators.required]"
              maxlength="150"
            ></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field
              v-model="recruiter.user.lastName"
              label="Last name"
              dense
              outlined
              :rules="[validators.required]"
              maxlength="150"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="recruiter.user.email"
              label="E-mail"
              dense
              outlined
              :rules="[validators.required, validators.emailValidator]"
              maxlength="254"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="recruiter.user.phone"
              dense
              label="Phone"
              outlined
              :rules="[validators.required]"
              maxlength="31"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="mt-4 me-3"
              :loading="loading"
              type="submit"
              :disabled="!valid || !areDataChanged"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              v-t="'discard'"
              color="secondary"
              outlined
              class="mt-4"
              :disabled="!areDataChanged"
              @click="resetForm"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required, emailValidator } from '@core/utils/validation'
import _ from 'lodash'

export default {
  setup() {
    const accountForm = ref(null)
    const valid = ref(true)
    const recruiter = ref({
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
    })
    const frozenRecruiter = ref({})
    const loading = ref(false)

    const { getRecruiterProfile, updateRecruiterProfile } = useActions('recruiter', [
      'getRecruiterProfile',
      'updateRecruiterProfile',
    ])

    const resetForm = () => {
      recruiter.value = _.cloneDeep(frozenRecruiter.value)
    }

    const handleFormSubmit = async () => {
      const isFormValid = accountForm.value.validate()

      if (!isFormValid) return
      loading.value = true
      const { success } = await updateRecruiterProfile({
        id: recruiter.value.id,
        user: recruiter.value.user,
      })
      if (success) {
        frozenRecruiter.value = Object.freeze(_.cloneDeep(recruiter.value))
      }
      loading.value = false
      resetForm()
    }

    const areDataChanged = computed(() => {
      return (
        recruiter.value?.user?.firstName !== frozenRecruiter.value?.user?.firstName ||
        recruiter.value?.user?.lastName !== frozenRecruiter.value?.user?.lastName ||
        recruiter.value?.user?.phone !== frozenRecruiter.value?.user?.phone ||
        recruiter.value?.user?.email !== frozenRecruiter.value?.user?.email
      )
    })

    onMounted(async () => {
      recruiter.value = await getRecruiterProfile()
      frozenRecruiter.value = Object.freeze(_.cloneDeep(recruiter.value))
    })

    return {
      accountForm,
      valid,
      recruiter,
      frozenRecruiter,
      loading,
      getRecruiterProfile,
      handleFormSubmit,
      resetForm,
      areDataChanged,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>
