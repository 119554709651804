<template>
  <v-card flat class="mt-5">
    <v-form ref="resetPasswordForm" v-model="valid" @submit.prevent="handleFormSubmit">
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Current Password"
                :rules="[validators.required]"
                outlined
                dense
                class="mb-6"
                hide-details="auto"
                maxlength="128"
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="New Password"
                :rules="[validators.required, validators.passwordValidator]"
                outlined
                dense
                class="mb-4"
                hide-details="auto"
                maxlength="128"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Confirm New Password"
                :rules="[validators.required, validators.confirmPasswordValidator(cPassword, newPassword)]"
                outlined
                dense
                hide-details="auto"
                maxlength="128"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="6" class="d-none d-sm-flex justify-center position-relative">
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <!--      <v-divider></v-divider>-->

      <div class="pa-3">
        <!--        <v-card-title class="flex-nowrap">-->
        <!--          <v-icon class="text&#45;&#45;primary me-3">-->
        <!--            {{ icons.mdiKeyOutline }}-->
        <!--          </v-icon>-->
        <!--          <span class="text-break">Two-factor authentication</span>-->
        <!--        </v-card-title>-->

        <!--        <v-card-text class="two-factor-auth text-center mx-auto">-->
        <!--          <v-avatar color="primary" class="v-avatar-light-bg primary&#45;&#45;text mb-4" rounded>-->
        <!--            <v-icon size="25" color="primary">-->
        <!--              {{ icons.mdiLockOpenOutline }}-->
        <!--            </v-icon>-->
        <!--          </v-avatar>-->
        <!--          <p class="text-base text&#45;&#45;primary font-weight-semibold">-->
        <!--            Two factor authentication is not enabled yet.-->
        <!--          </p>-->
        <!--          <p class="text-sm text&#45;&#45;primary">-->
        <!--            Two-factor authentication adds an additional layer of security to your account by-->
        <!--            requiring more than just a password to log in. Learn more.-->
        <!--          </p>-->
        <!--        </v-card-text>-->

        <!-- action buttons -->
        <v-card-text>
          <v-btn color="primary" class="me-3 mt-3" :loading="loading" :disabled="!valid" type="submit">
            {{ $t('save') }}
          </v-btn>
          <v-btn v-t="'discard'" color="secondary" outlined class="mt-3" type="reset" @click.prevent="resetForm" />
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required, confirmPasswordValidator, passwordValidator } from '@core/utils/validation'

export default {
  setup() {
    const valid = ref(true)
    const resetPasswordForm = ref(null)
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')
    const loading = ref(false)

    const { changeRecruiterProfilePassword } = useActions('recruiter', ['changeRecruiterProfilePassword'])
    const handleFormSubmit = async () => {
      const isFormValid = resetPasswordForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const passwordChanged = await changeRecruiterProfilePassword({
        oldPassword: currentPassword.value,
        newPassword: newPassword.value,
      })
      loading.value = false
      if (passwordChanged) resetPasswordForm.value.reset()
    }

    const resetForm = () => {
      resetPasswordForm.value.reset()
    }

    return {
      valid,
      resetPasswordForm,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      loading,
      handleFormSubmit,
      resetForm,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
        confirmPasswordValidator,
        passwordValidator,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -2.5rem;
}
</style>
