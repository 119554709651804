<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <account-settings-account></account-settings-account>
      </v-tab-item>

      <v-tab-item>
        <account-settings-security></account-settings-security>
      </v-tab-item>

      <!--      <v-tab-item>-->
      <!--        <account-settings-info-->
      <!--          :information-data="accountSettingData.information"-->
      <!--        ></account-settings-info>-->
      <!--      </v-tab-item>-->

      <!--      <v-tab-item>-->
      <!--        <account-settings-billing></account-settings-billing>-->
      <!--      </v-tab-item>-->
      <!--      <v-tab-item>-->
      <!--        <account-settings-notification-->
      <!--          :notification-data="accountSettingData.notification"-->
      <!--        ></account-settings-notification>-->
      <!--      </v-tab-item>-->
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'

// import AccountSettingsInfo from './AccountSettingsInfo.vue'
// import AccountSettingsBilling from './AccountSettingsBilling.vue'
// import AccountSettingsNotification from './AccountSettingsNotification.vue'

export default {
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,

    // AccountSettingsInfo,
    // AccountSettingsBilling,
    // AccountSettingsNotification,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Account', icon: mdiAccountOutline },
      { title: 'Security', icon: mdiLockOpenOutline },

      // { title: 'Info', icon: mdiInformationOutline },
      // { title: 'Billing', icon: mdiBookmarkOutline },
      // { title: 'Notifications', icon: mdiBellOutline },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
      },
    }
  },
}
</script>
